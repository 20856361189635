// Here you can add other styles
.c-app{
 background-color: #d2d4d7;
}
#footer-out{
background-color: #d2d4d7;
}
#cgmLogo{
    margin-top: 10px;
    margin-bottom: 10px;
}
.btn-primary {
    color: #fff;
    background-color: #126cae;
    border-color: #126cae;
}

.btn-primary:hover {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
}
.btn-primary:active {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
}
.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
    box-shadow: 0 0 0 0.2rem;
}
.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #126cae;
}
a {
    text-decoration: none;
    background-color: transparent;
    color: #126cae;
}
a:hover {
    text-decoration: none;
    background-color: transparent;
    color: #01396a;
}
.btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
}
.btn-link {
    font-weight: 400;
    color: #126cae;
    text-decoration: none;
    }
    .bg-primary {
        background-color: #126cae !important;
        }
.form-control:focus {
    color: #768192;
    background-color: #fff;
    border-color: #01396a;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
}

.dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    color: #fff;
    background-color: #01396a;
}

.c-sidebar { 
    background: #2d4280 !important; 
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.addNewCar {
  /* background-color: #555555; */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  padding: 12px 20px;
  /* text-align: center;
  text-decoration: none;
  display: inline-block; */
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

#yes {
  /* background-color: #555555; */
  border: none;
  color: white;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  margin-right: 60px;
}

.c-footer{
    display:inline-flex !important;
}
#button-nav:focus{
    outline:0 !important;
}

#no {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #f44336;
}


.delete {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 12px 20px;
  /* padding: 8px 12px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #f44336;
}



#notification {
  /* position:absolute;
      top:0;
      right:0;
      z-index: 1000;
      background-color: #4CAF50;
      color: white;
      font-weight: 500;
      border-color: #4CAF50;
      font-size: 18px; */
  float: right;
  /* background: #4CAF50; */
  color: #ffffff;
  border-radius: 15px;
  padding: 0.5em 2em 0.5em 2em;
  margin: 1em;
  /* display: none; */
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
}



.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}


/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  margin-left: 30%;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

        .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
      }
  
      .close:hover,
      .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
      }
  
      


/* The Close Button */
.closeButt {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}



.closeButt:hover,
.closeButt:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


#outer {
  width: 100%;
  text-align: center;
}

.inner {
  display: inline-block;
}

.valid {
  background: #4CAF50;
}

.error {
  background: red;
}

.alert>.start-icon {
  margin-right: 0;
  min-width: 20px;
  text-align: center;
}

.alert>.start-icon {
  margin-right: 5px;
}

.greencross {
  font-size: 18px;
  color: #25ff0b;
  text-shadow: none;
}

.alert-simple.alert-success {
  border: 1px solid rgba(36, 241, 6, 0.46);
  background-color: rgba(7, 149, 66, 0.12156862745098039);
  box-shadow: 0px 0px 2px #259c08;
  color: #0ad406;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}

.alert-success:hover {
  background-color: rgba(7, 149, 66, 0.35);
  transition: 0.5s;
}

.alert-simple.alert-info {
  border: 1px solid rgba(6, 44, 241, 0.46);
  background-color: rgba(7, 73, 149, 0.12156862745098039);
  box-shadow: 0px 0px 2px #0396ff;
  color: #0396ff;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}

.alert-info:hover {
  background-color: rgba(7, 73, 149, 0.35);
  transition: 0.5s;
}

.blue-cross {
  font-size: 18px;
  color: #0bd2ff;
  text-shadow: none;
}

.alert-simple.alert-warning {
  border: 1px solid rgba(241, 142, 6, 0.81);
  /* background-color: rgba(220, 128, 1, 0.16); */
  background-color: #ffb103;
  box-shadow: 0px 0px 2px #ffb103;
  /* color: #ffb103; */
  color: white;
  /* text-shadow: 2px 1px #00040a; */
  transition: 0.5s;
  cursor: pointer;
  text-shadow: #000 1px 2px 7px;
}

/* .alert-warning:hover {
  background-color: rgba(220, 128, 1, 0.33);
  transition: 0.5s;
} */

.warning {
  font-size: 18px;
  color: #ffb40b;
  text-shadow: none;
}

.alert-simple.alert-danger {
  border: 1px solid rgba(241, 6, 6, 0.81);
  background-color: rgba(220, 17, 1, 0.16);
  box-shadow: 0px 0px 2px #ff0303;
  color: #ff0303;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}

.alert-danger:hover {
  background-color: rgba(220, 17, 1, 0.33);
  transition: 0.5s;
}

.danger {
  font-size: 18px;
  color: #ff0303;
  text-shadow: none;
}

.alert-simple.alert-primary {
  border: 1px solid rgba(6, 241, 226, 0.81);
  background-color: rgba(1, 204, 220, 0.16);
  box-shadow: 0px 0px 2px #03fff5;
  color: #03d0ff;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}

.alert-primary:hover {
  background-color: rgba(1, 204, 220, 0.33);
  transition: 0.5s;
}

.alertprimary {
  font-size: 18px;
  color: #03d0ff;
  text-shadow: none;
}

.square_box {
  position: absolute;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top-left-radius: 45px;
  opacity: 0.302;
}

.square_box.box_three {
  background-image: -moz-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -webkit-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -ms-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  opacity: 0.059;
  left: -80px;
  top: -60px;
  width: 500px;
  height: 500px;
  border-radius: 45px;
}

.square_box.box_four {
  background-image: -moz-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -webkit-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  background-image: -ms-linear-gradient(-90deg, #290a59 0%, #3d57f4 100%);
  opacity: 0.059;
  left: 150px;
  top: -25px;
  width: 550px;
  height: 550px;
  border-radius: 45px;
}

.alert:before {
  content: '';
  position: absolute;
  width: 0;
  height: calc(100% - 44px);
  border-left: 1px solid;
  border-right: 2px solid;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 20px;
}

.fa-times {
  -webkit-animation: blink-1 2s infinite both;
  animation: blink-1 2s infinite both;
}

.profile-label {

    font-size: 18px;
    font-weight: 500;
    /* padding-left: 10px; */
    padding-right: 15px;

  }
  .profile-span {
    font-size: 20px;
  }

.carLogo {
  width: 80px; 
  height: 80px;
}
.dd-selected-text{
  position: absolute;
  top: 36%;
  font-size: 17px;
  line-height: 20px !important;
}
.closeButt{
    top: 0px;
    left: 9px;
    position: absolute;
}

.cardLogo {
    width: 100px; 
    height: 80px;
  }

  .table-hover tbody tr:hover {
  color: #4f5d73;
  /* background-color: rgba(0, 0, 21, 0.075); */
  background-color: #ccc;
}
  .closeButt{
      top: 0px;
      left: 9px;
      position: absolute;
  }

  .delete {
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    padding: 12px 20px;
    /* padding: 8px 12px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: #f44336;
  }


  .backgorund-powered {
    background: none !important;
}

.editProfile {
    background-color: #555555;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  a.button1 {
    display: inline-block;
    padding: 0.35em 1.2em;
    border: 0.1em solid #FFFFFF;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.12em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    text-align: center;
    transition: all 0.2s;
  }

  a.button1:hover {
    color: #000000;
    background-color: #FFFFFF;
  }

  .enterPass {
    background-color: #555555;
    border: none;
    color: white;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
/**
* ----------------------------------------
* animation blink-1
* ----------------------------------------
*/
@-webkit-keyframes blink-1 {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes blink-1 {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {

        /* Force table to not be like tables anymore */
        #carTable
        #carThead,
        .carTbody,
        .carTh,
        .carTd,
        .carTr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        #carThead .carTr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        /* .paymentsTr,
        ordersTr {
            margin: 0 0 1rem 0;
        } */

        .carTr:nth-child(odd) {
            background: #eee;
        }

        .carTd {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50% !important;
        }

        .carTd:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            /* top: 0; */
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
        }

        .delete {
          padding: 5px;
          font-size: 11px;
        }

        .carLogo {
          width: 65px;
          height: 65px;
        }

        .table-hover tbody tr:hover {
          color: #4f5d73;
          /* background-color: rgba(0, 0, 21, 0.075); */
          background-color: #ccc;
        }

         /* Force table to not be like tables anymore */
         #cardTable
         #cardThead,
         .cardTbody,
         .cardTh,
         .cardTd,
         .cardTr {
             display: block;
         }

         /* Hide table headers (but not display: none;, for accessibility) */
         #cardThead .cardTr {
             position: absolute;
             top: -9999px;
             left: -9999px;
         }

         /* .paymentsTr,
         ordersTr {
             margin: 0 0 1rem 0;
         } */

         .cardTr:nth-child(odd) {
             background: #eee;
         }

         .cardTd {
             /* Behave  like a "row" */
             border: none;
             border-bottom: 1px solid #eee;
             position: relative;
             padding-left: 50% !important;
         }

         .cardTd:before {
             /* Now like a table header */
             position: absolute;
             /* Top/left values mimic padding */
             /* top: 0; */
             left: 6px;
             width: 45%;
             padding-right: 10px;
             white-space: nowrap;
         }

        .cardLogo {
            width: 57px;
            height: 47px;
          }

        /*
    Label the data
You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
    */


    .cardTd:nth-of-type(1):before {
        content: "Вид";
        font-weight: bold;
        top:33%;
    }

    .cardTd:nth-of-type(2):before {
        content: "Номер";
        font-weight: bold;
    }

    .cardTd:nth-of-type(3):before {
        content: "Изтрий карта";
        font-weight: bold;
        top: 27%;
    }

        .carTd:nth-of-type(1):before {
            content: "Марка";
            font-weight: bold;
            top:33%;
        }

        .carTd:nth-of-type(2):before {
            content: "Псевдоним";
            font-weight: bold;
        }

        .carTd:nth-of-type(3):before {
            content: "Регистрационен номер";
            font-weight: bold;
        }

        .carTd:nth-of-type(4):before {
            content: "Изтрий автомобил";
            font-weight: bold;
            top: 27%;
        }
        .table th, .table td {
          padding: 0.3rem;
        }
        .modal-content {
              background-color: #fefefe;
              margin: auto;
              top:30%;
              padding: 20px;
              border: 1px solid #888;
              width: 95%;
          }
          .closeButt {
              padding-bottom: 20px;
          }
          
                  }
      @media only screen and (max-width: 450px){
        .ordersTd {
            text-align: center;
              padding-left: 5% !important;
          }
          .ordersTd:before {
              position: relative;
          }
          .paymentsTd{
            text-align: center;
            padding-left: 5% !important;
          
          }
          .paymentsTd:before {
              position: relative;
          }
              .carTd {
                text-align: center;
                  padding-left: 5% !important;
              }
              .carTd:before {
                  position: relative;
              }
              .c-footer {
                  display: block;
                  text-align: center;
              }
      }
      .backgorund-powered{
        background: none !important;
      }
      .text-powered{
          font-size:12px;
          padding-bottom:2px;
          color: #161616;
      }
      .text-right{
        text-align: center !important;
      }

      .c-footer {
        display: block;
        text-align: center;
    }
    .dd-selected-image {
      float:none !important;
    }
    .mgt-20{
        margin-top: 20px;
      }
 /* Force table to not be like tables anymore */
 #paymentsTable,
 #paymentsThead,
 .paymentsTbody,
 .paymentsTh,
 .paymentsTd,
 .paymentsTr,
 #ordersTable,
 #ordersThead,
 .ordersTbody,
 .ordersTh,
 .ordersTd,
 .ordersTr {
     display: block;
 }

 /* Hide table headers (but not display: none;, for accessibility) */
 #paymentsThead .paymentsTr {
     position: absolute;
     top: -9999px;
     left: -9999px;
 }

 #ordersThead .ordersTr {
     position: absolute;
     top: -9999px;
     left: -9999px;
 }
 .mr-none{
    margin-right: 0px;
    margin-left: 0px;
}

            /* .paymentsTr,
            ordersTr {
                margin: 0 0 1rem 0;
            } */

            .paymentsTr:nth-child(odd),
            .ordersTr:nth-child(odd){
                background: #eee;
            }

            .paymentsTd,
            .ordersTd {
                /* Behave  like a "row" */
                border: none;
                border-bottom: 1px solid #eee;
                position: relative;
                padding-left: 50% !important;
            }

            .paymentsTd:before,
            .ordersTd:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                /* top: 0; */
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
            }
            .mobile-button{
                width: 100% !important;
                padding-bottom: 6px !important;
            }

             /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
        .paymentsTd:nth-of-type(1):before {
            content: "Дата";
            font-weight: bold;
        }

        .paymentsTd:nth-of-type(2):before {
            content: "Сума";
            font-weight: bold;
        }

        .paymentsTd:nth-of-type(3):before {
            content: "Описание";
            font-weight: bold;
        }

        .paymentsTd:nth-of-type(4):before {
            content: "Метод на плащане";
            font-weight: bold;
        }

        .paymentsTd:nth-of-type(5):before {
            content: "Платено чрез";
            font-weight: bold;
        }

        .paymentsTd:nth-of-type(6):before {
            content: "Платено през";
            font-weight: bold;
        }


        .ordersTd:nth-of-type(1):before {
            content: "Дата";
            font-weight: bold;
        }

        .ordersTd:nth-of-type(2):before {
            content: "Номер на автомобил";
            font-weight: bold;
        }

        .ordersTd:nth-of-type(3):before {
            content: "Локация";
            font-weight: bold;
        }

        .ordersTd:nth-of-type(4):before {
            content: "Описание";
            font-weight: bold;
        }

        .ordersTd:nth-of-type(5):before {
            content: "Платено от";
            font-weight: bold;
        }

        .ordersTd:nth-of-type(6):before {
            content: "Платено през";
            font-weight: bold;
        }

        /* td:nth-of-type(6):before {
            content: "Secret Alias";
        }

        td:nth-of-type(7):before {
            content: "Date of Birth";
        }

        td:nth-of-type(8):before {
            content: "Dream Vacation City";
        }

        td:nth-of-type(9):before {
            content: "GPA";
        }

        td:nth-of-type(10):before {
            content: "Arbitrary Data";
        } */
    
        .table-hover tbody tr:hover {
color: #4f5d73;
background-color: #ccc;
        }
      @media only screen and (max-width: 991px){
  .c-header-nav{
    display:none;
  }
  .margin-auto-logo-mobile{
    margin: auto;
  }
  .mobile-right{
    text-align: right;
    padding-top:5px;
  }
  .mobile-left{
    text-align: left;
  }
  .text-powered{
          font-size:10px;
          padding-bottom:2px;
          padding-right: 3px;
      }
      .textcenter{
          text-align: center;
      }
}
.c-header{
    background-color: #2d4280;
}
#nav-ham{
    color:white;
}


@media only screen and (max-width: 400px){
    #changePass{
      margin-bottom: 10px;
      width: 100%;
    }
    #deleteAcc{
      width: 100%;
    }
  }
@media only screen and (max-width: 630px){
  .text-powered{
    font-size:10px;
    padding-right: 5px;
  }
  .mobile-right {
text-align: right;
padding-right: 10px;
padding-top: 5px;
}
}

@media only screen and (min-width: 500px) and (max-width: 590px){
  .text-powered{
    font-size:10px;
  }
  .mobile-right {
    text-align: right;
      padding-right: 35px;
      padding-top: 5px;
  }

}
@media only screen and (min-width: 991px){
  .mobile-menu-view{
      display: none;
  }
  .mobile-logo-powered{
        display: none;
      }
      .text-powered{
        font-size:12px;
        padding-bottom:2px;
    }


}
@media only screen and (min-width: 776px){
      .float-right-desktop{
        text-align: right;
    }
    .mobile-logo-powered{
        display: none;
      }
    
}

@media only screen and (max-width: 776px){
    .desktop-logo-powered{
      display: none;
    }
    .mgb-20{
        margin-bottom: 20px;
      }
    
}
.mgb-20{
    margin-bottom: 20px;
}

@media only screen and (max-width: 576px){
    .c-footer {
        padding: 0;
        margin: 0;
        list-style: none;
        
        -ms-box-orient: horizontal;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
}

      .flex-item:nth-of-type(1) { order: 2; }
      .flex-item:nth-of-type(2) { order: 1; }
      .flex-item:nth-of-type(3) { order: 3; }

      .flex-item{
      text-align: center;
    }
    .mobile-block{
      display: block;
    }
  }

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

    .safari_only { 

        flex: 0 0 112px !important;

    }
}}

@media all and (max-width:30em) {
    a.button1 {
      display: block;
      margin: 0.4em auto;
    }
  }

  @media screen and (min-width:576px) and (max-width:767px) {
    .filter {
        margin-top: 33px;
    }

}